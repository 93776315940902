import axios from 'axios';
import moment from 'moment';

export default class PaymentAccountsApi {
  pix = new Pix();

  async getBalance(param = '') {
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/balance?transferType=${param}`
    );
    return result.data;
  }

  async getSettings() {
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/settings`
    );
    return result.data;
  }

  async setSettings(params) {
    const result = await axios.put(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/settings/transfer-days`,
      params
    );
    return result.data;
  }

  async getListTransferences(filter) {
    let query = '?';
    if (filter.status !== 'all') {
      query += `&status=${filter.status}`;
    }
    if (filter.startDate.length !== 0 && filter.endDate.length) {
      query += `&startDate=${filter.startDate}&endDate=${filter.endDate}`;
    }
    if (filter.offset.index !== null) {
      query += `&index=${filter.offset.index}`;
    }

    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/cashout-request${query}`
    );
    return result.data;
  }

  async getStatementsFile(params) {
    let query = '?fileType=csv';
    Object.entries(params).forEach((element) => {
      // element[0] contem o nome do campo
      // element[1] contem o valor do campo
      if (element[1] != null) query += `&${element[0]}=${element[1]}`;
    });

    await axios
      .get(
        `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/statements/return-file${query}`,
        { responseType: 'arraybuffer' }
      )
      .then((response) => {
        let blob = new Blob([response.data], {
          type: 'text/csv',
        });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = `Statements_${moment(new Date()).format(
          'DD.MM.YYYY'
        )}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  async getListTransferencesSplitters(filter) {
    const params = {
      startDate: filter.startDate,
      endDate: filter.endDate,
      status: filter.status !== 'all' ? filter.status : null,
      index: filter.offset.index,
      length: filter.offset.limit,
    };

    const result = await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/cashout-request/splitters/history`,
      params
    );

    return result.data;
  }

  async getExtract(params) {
    let query = '?';
    Object.entries(params).forEach((element) => {
      // element[0] contem o nome do campo
      // element[1] contem o valor do campo
      if (element[1] != null) query += `&${element[0]}=${element[1]}`;
    });
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/statements${query}`
    );
    return result.data;
  }

  async getDetailsExtract(filter) {
    filter.indice = filter.indice === undefined ? 0 : filter.indice;
    filter.length = filter.length === undefined ? 30 : filter.length;

    let query = `&index=${filter.indice}&length=${filter.length}`;
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/statements/${filter.date}/details?type=${filter.type}${query}`
    );
    return result.data;
  }

  async requestTransferOwn(value) {
    const model = {
      amount: value,
    };
    const result = await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/cashout-request`,
      model
    );
    return result;
  }

  async requestTransferOther(value, splitterId) {
    const model = {
      amount: value,
      splitterId: splitterId,
    };
    const result = await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/cashout-request/splitters/internal`,
      model
    );
    return result;
  }

  async requestTransferBetweenHolders(amount, holderTaxDocument, token) {
    const options = {
      headers: { '2FA': token },
    };

    var model = {
      requests: [
        {
          amount,
          holderTaxDocument,
        },
      ],
    };

    var result = await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/cashout-request/between-holders`,
      model,
      options
    );

    return result;
  }

  async getMinValueTransfer() {
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/cashout-request/minimum-amount`
    );
    return result.data;
  }

  async getDetail(detailsLink) {
    const result = await axios.get(`${detailsLink}`);
    return result.data;
  }

  async getDetailsTransfer(id) {
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/transfers/${id}`
    );
    return result;
  }

  async createDestination(destination) {
    const result = await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/destinations`,
      destination
    );
    return result;
  }

  async getDestinations() {
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/destinations`
    );
    return result;
  }

  async getSplitterBalance(ids) {
    const result = await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/splitter-balance`,
      { ids: [ids] }
    );
    return result.data;
  }

  async getMinimumValueToTransfer() {
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/cashout-request/minimum-amount`
    );
    return result;
  }

  async createCashoutRequest(amount, destination, authCode) {
    const model = { amount, destination };
    const options = {
      headers: { AuthCode: authCode, AuthCodeType: 'ted' },
    };
    const result = await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/cashout-request/ted`,
      model,
      options
    );

    return result;
  }

  async createSplitterCashoutRequest(amount, splitterId, note, token) {
    const model = { amount, splitterId, note };
    const options = {
      headers: { '2FA': token },
    };
    const result = await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/cashout-request/splitters`,
      model,
      options
    );

    return result;
  }

  async createSplitterToAccountCashoutRequest(
    amount,
    destination,
    splitterId,
    note,
    authCode
  ) {
    const model = { amount, destination, splitterId, note };

    const options = {
      headers: { AuthCode: authCode, AuthCodeType: 'ted' },
    };

    const result = await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/cashout-request/ted/splitter`,
      model,
      options
    );

    return result;
  }

  async getSplitterSettings(id) {
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/settings/splitter/${id}`
    );

    return result.data;
  }

  async createSplitterTransferDays(payload) {
    const result = await axios.put(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/settings/splitters/transfer-days`,
      payload
    );

    return result;
  }

  async getAuthCode(parameters, type) {
    try {
      const model = parameters;
      const result = await axios.post(
        `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/auth-code/${type}`,
        model
      );
      return result;
    } catch (error) {
      return error;
    }
  }

  async getListPayments(filter) {
    let query = `?&index=${filter.offset.index}&length=${filter.offset.limit}`;

    if (filter.status !== 'all') {
      query += `&status=${filter.status}`;
    }

    if (filter.startDate.length !== 0 && filter.endDate.length) {
      query += `&startDate=${filter.startDate}&endDate=${filter.endDate}`;
    }

    if (filter.barCode.length !== 0) {
      query += `&barCode=${filter.barCode}`;
    }

    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/bills-payment${query}`
    );

    return result.data;
  }

  async cancelBillsPayment(id, token) {
    let model = {
      paymentsIds: [id],
    };

    let headers = {
      '2FA': token,
    };

    const result = await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/bills-payment/cancel`,
      model,
      { headers }
    );

    return result;
  }

  async getBillInfo(barCode, documentType) {
    var model = {
      barCode: barCode,
      documentType: documentType,
    };

    const result = await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/bills-payment/validate`,
      model
    );

    return result;
  }

  async sendBillPayment(model, authCode) {
    const options = {
      headers: { authCode: authCode },
    };

    return await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_V2_URL}/bills-payment/send`,
      model,
      options
    );
  }

  async getTransferLimits() {
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_V2_URL}/transfer-limit`
    );

    return result.data;
  }

  async getMaxLimits(sameHolder) {
    const result = await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_V2_URL}/transfer-limit/max-limits?sameHolderDestination=${sameHolder}`
    );

    return result;
  }
  async updateLimit(model) {
    return await axios.put(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_V2_URL}/transfer-limit/seller`,
      model
    );
  }
}

class Pix {
  async getKeys() {
    return await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/keys`
    );
  }

  async registerPixKey(model, authCode = null, deviceId, requestId) {
    let options = null;

    if (authCode) {
       options = {
        headers: { authcode: authCode, deviceId: deviceId, requestId: requestId },
      };
      return await axios.post(
        `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/keys`,
        model,
        options
      );
    }

    options = {
      headers: { deviceId: deviceId, requestId: requestId },
    };

    return await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/keys`,
      model,
      options
    );
  }

  async deleteKey(type, key, authCode, deviceId, requestId) {
    var options = {
      headers: { authCode: authCode, deviceId: deviceId, requestId: requestId },
    };

    return await axios.delete(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/keys/${type}/${key}`,
      options
    );
  }

  async getSugestions() {
    return await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/keys/suggested`
    );
  }

  async getPaymentOrders(filter, offset = 0, length = 30) {
    let query = `?length=${length}&index=${offset}`;
    // object.entries realiza uma varredura em todos os campos do objeto
    Object.entries(filter).forEach((element) => {
      // element[0] contem o nome do campo
      // element[1] contem o valor do campo
      if (element[1] != null) query += `&${element[0]}=${element[1]}`;
    });
    return await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/payment-orders${query}`
    );
  }

  mountOrderDetailsLink(id) {
    return `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/payment-order/${id}`;
  }

  async getKeyOwnerDetails(key) {
    return await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/${key}/details`
    );
  }

  async createOrderPayment(model, authCode, deviceId, requestId) {
    const options = {
      headers: { authcode: authCode, deviceId: deviceId, requestId: requestId },
    };
    return await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/payment-order`,
      model,
      options
    );
  }

  async getPixInstitutions(filter = [], offset = 0, length = 30) {
    let query = `?length=${length}&index=${offset}`;
    // object.entries realiza uma varredura em todos os campos do objeto
    Object.entries(filter).forEach((element) => {
      // element[0] contem o nome do campo
      // element[1] contem o valor do campo
      if (element[1] != null) query += `&${element[0]}=${element[1]}`;
    });
    return await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/institutions${query}`
    );
  }

  async getAllPixInstitutions() {
    return await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/institutions?allitems=true`
    );
  }

  async readQrCode(code) {
    const model = { qrCodeValue: code };
    return await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/qrcode/read`,
      model
    );
  }

  async createStaticQrCode(model) {
    return await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/qrcode/static`,
      model
    );
  }

  async createDynamicQrCode(model) {
    return await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/qrcode/dynamic`,
      model
    );
  }

  async getQrCodeImage(id) {
    return await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/qrcode/image/${id}`
    );
  }

  async getQrCode(filters) {
    let query = '?';
    if (
      filters.typeDate !== 'all' &&
      filters.startDate !== null &&
      filters.endDate !== null
    )
      query += `&typeDate=${filters.typeDate}&startDate=${moment(
        filters.startDate
      ).format('YYYY-MM-DD')}&endDate=${moment(filters.endDate).format(
        'YYYY-MM-DD'
      )}`;
    if (filters.base64 !== null) query += `&base64=${filters.base64}`;
    if (filters.pixKey !== null) query += `&pixKey=${filters.pixKey}`;
    if (filters.type !== 'all') query += `&type=${filters.type}`;
    if (filters.offset.index != null) query += `&index=${filters.offset.index}`;
    return await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/qrcode${query}`
    );
  }

  async generateQrCodeImage(id) {
    let url = `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/qrcode/image/${id}`;
    return await axios.get(url, { responseType: 'arraybuffer' });
  }

  mountQrCodeImageUrl(id) {
    return `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/qrcode/image/${id}`;
  }

  async getClaims(filters) {
    var queryString = $.param(filters);
    return await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/keys/claims?${queryString}`
    );
  }

  async approveClaim(claimId) {
    return await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/keys/claim/${claimId}/approve`
    );
  }

  async cancelClaim(claimId, authCode) {
    let options;
    if (authCode) {
      options = {
        headers: { authCode: authCode },
      };
    }
    return await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/keys/claim/${claimId}/cancel`,
      null,
      options
    );
  }

  async claimKey(model, authCode, deviceId, requestId) {
    let options;

    if (authCode) {
      options = {
        headers: { authCode: authCode , deviceId: deviceId, requestId: requestId },
      };
    } else {
         options = {
        headers: {deviceId: deviceId, requestId: requestId },
      };
    }
    return await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/keys/claim`,
      model,
      options
    );
  }

  async refundOrder(orderId, refundValue, authCode) {
    const options = {
      headers: { authCode: authCode },
    };

    const body = {
      refundValue,
    };

    return await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_URL}/pix/payment-order/${orderId}/refund`,
      body,
      options
    );
  }
}
